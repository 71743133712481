<template>
	<div class="wrapper">
		<div class="top-btn" ref="topBtn">
            <el-button size="small" type="primary" @click="search()">搜索</el-button>
		</div>
		<el-table :data="list" :max-height="tableHeight" :header-cell-style="{background:'#f5f7fa'}" style="width: 100%">
			<el-table-column prop="enter_member_id" label="活动id"></el-table-column>
			<el-table-column prop="member_name" label="活动名称"></el-table-column>
            <el-table-column prop="member_name" label="活动时间"></el-table-column>
            <el-table-column prop="member_name" label="统计范围"></el-table-column>
            <el-table-column prop="member_name" label="活动状态"></el-table-column>
            <el-table-column prop="member_name" label="活动城市"></el-table-column>
            <el-table-column prop="member_name" label="活动分类"></el-table-column>
            <el-table-column label="操作">
				<template slot-scope="scope">
					<el-link type="primary" @click="toRank(scope.row.id)">查看排行</el-link>
				</template>
			</el-table-column>
		</el-table>
		<el-pagination v-if="total > 0" style="padding-top: 30px; text-align: center;" @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="limit" layout="prev, pager, next, jumper" :total="total">
		</el-pagination>
        <likeRank :dialogVisible="dialogVisible" :id="id" @close="close"></likeRank>
	</div>
</template>
<script>
    import likeRank from './component/likeRank.vue'
	import { shareList } from '@/api/base.js';
	export default {
		name: "LikeActivity",
		data() {
			return {
				// 列表
				total: 0,
				limit: 20,
				currentPage: 1,
                list: [{id: 1}],
                // 弹框
                dialogVisible: false,
                id: 0,
			}
		},
		created() {
			// this.getList()
		},
		components: {
            likeRank
		},
		methods: {
            // 搜索
            search() {

            },
			// 获取列表
			getList() {
				shareList({
					limit: this.limit,
					page: this.currentPage,
				}).then((res) => {
					this.total = res.data.total;
					this.list = res.data.data;
				}).catch((err) => {
					console.log(err, 222)
				})
			},
			// 页码修改
			handleCurrentChange(val) {
				this.currentPage = val;
				this.getList();
            },
            // 查看排行
            toRank(id) {
                this.id = id;
                this.dialogVisible = true
            },
            // 关闭弹框
            close() {
                this.dialogVisible = false
            },
		}
	};
</script>
