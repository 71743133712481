<template>
	<div class="dialog">
		<el-dialog title="排行" :visible.sync="dialogVisible" width="900px" :before-close="close" :close-on-click-modal='false'>
            <div class="top-btn" ref="topBtn">
                <el-button size="small" type="primary" @click="search()">搜索</el-button>
            </div>
            <el-table :data="list" :max-height="tableHeight" :header-cell-style="{background:'#f5f7fa'}" style="width: 100%">
                <el-table-column prop="enter_member_id" label="排名"></el-table-column>
                <el-table-column prop="member_name" label="活动城市"></el-table-column>
                <el-table-column prop="member_name" label="活动分类"></el-table-column>
                <el-table-column prop="member_name" label="点赞数量"></el-table-column>
                <el-table-column prop="member_name" label="统计时间"></el-table-column>
                <el-table-column prop="member_name" label="课程名称"></el-table-column>
                <el-table-column prop="member_name" label="课程id"></el-table-column>
                <el-table-column prop="member_name" label="机构名称"></el-table-column>
                <el-table-column prop="member_name" label="机构id"></el-table-column>
            </el-table>
            <el-pagination v-if="total > 0" style="padding-top: 30px; text-align: center;" @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="limit" layout="prev, pager, next, jumper" :total="total">
            </el-pagination>
        </el-dialog>
    </div>
</template>
<script>
	import {
		shareList
	} from '@/api/base.js';
	export default {
        name: "LikeRank",
        props: {
			dialogVisible: {
				type: Boolean,
				default: false
			},
			id: {
				type: Number,
				default: 0
			}
		},
		data() {
			return {
				// 列表
				total: 0,
				limit: 20,
				currentPage: 1,
				list: [],
			}
		},
		created() {
			// this.getList()
		},
		components: {
		},
		methods: {
            // 搜索
            search() {

            },
			// 获取列表
			getList() {
				shareList({
					limit: this.limit,
					page: this.currentPage,
				}).then((res) => {
					this.total = res.data.total;
					this.list = res.data.data;
				}).catch((err) => {
					console.log(err, 222)
				})
			},
			// 页码修改
			handleCurrentChange(val) {
				this.currentPage = val;
				this.getList();
            },
            // 关闭
			close() {
				this.$emit('close');
			},
		}
	};
</script>
